/* eslint-disable react/style-prop-object */
import React, { useState } from "react";
import emailjs from "emailjs-com";
import "./verificationCard.css";

const VerificationCard = () => {
  const [apartmentAddress, setApartmentAddress] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [ownerContact, setOwnerContact] = useState("");
  const [apartmentLocation, setApartmentLocation] = useState("");
  const [appointmentTime, setAppointmentTime] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [message, setMessage] = useState("");

  const handleInputChange = (e, setter) => {
    setter(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Define the parameters to send to EmailJS
    const templateParams = {
      apartment_address: apartmentAddress,
      owner_name: ownerName,
      owner_contact: ownerContact,
      apartment_location: apartmentLocation,
      appointment_time: appointmentTime,
      contact_number: contactNumber,
    };

    // Send the email using EmailJS
    emailjs
      .send(
        "service_59tmrb6", // Replace with your EmailJS service ID
        "template_jexh935", // Replace with your EmailJS template ID
        templateParams,
        "37wMJ_bmeR-ecCWLc" // Replace with your EmailJS user ID
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setMessage("Verification details submitted successfully!");
        },
        (error) => {
          console.error("FAILED...", error);
          setMessage("Failed to submit details. Please try again.");
        }
      );

    // Reset the form fields after submission
    setApartmentAddress("");
    setOwnerName("");
    setOwnerContact("");
    setApartmentLocation("");
    setAppointmentTime("");
    setContactNumber("");
  };

  return (
    <section className="verification-card">
      <div className="card-container">
        <h2>🏠 Apartment Verification</h2>
        <p style={{ color: '#27ae60' }}>Charges Apply</p>

        <p>Please fill out the form with the required details 📝.</p>
        <p><strong>Currently, we handle apartments only within Berlin.</strong></p>
        <p>
          Once you've provided the details, we'll confirm them with the owner and verify the apartment through an in-person visit.
        </p>
        <form onSubmit={handleSubmit} className="verification-form">
          <div className="form-group">
            <label htmlFor="apartmentAddress">Apartment Address:</label>
            <input
              type="text"
              id="apartmentAddress"
              value={apartmentAddress}
              onChange={(e) => handleInputChange(e, setApartmentAddress)}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="ownerName">Owner Name:</label>
            <input
              type="text"
              id="ownerName"
              value={ownerName}
              onChange={(e) => handleInputChange(e, setOwnerName)}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="ownerContact">Owner Contact Number (Email / Mobile):</label>
            <input
              type="text"
              id="ownerContact"
              value={ownerContact}
              onChange={(e) => handleInputChange(e, setOwnerContact)}
              required
            />
          </div>


          <div className="form-group">
            <label htmlFor="appointmentTime">Available Appointment Time:</label>
            <input
              type="datetime-local"
              id="appointmentTime"
              value={appointmentTime}
              onChange={(e) => handleInputChange(e, setAppointmentTime)}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="contactNumber">Your Contact Number:</label>
            <input
              type="tel"
              id="contactNumber"
              value={contactNumber}
              onChange={(e) => handleInputChange(e, setContactNumber)}
              required
            />
          </div>

          <button type="submit" className="btn-submit">
            Submit
          </button>
        </form>
        {message && <p className="success-message">{message}</p>}
      </div>
    </section>
  );
};

export default VerificationCard;
