/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import "./recent.css";

const RecentCard = ({ selectedLocation }) => {
  const [properties, setProperties] = useState([]);
  const [sortCriteria, setSortCriteria] = useState("");
  const [maxRent, setMaxRent] = useState(""); // State for max rent
  const [filteredProperties, setFilteredProperties] = useState([]); // State for filtered properties
  const history = useHistory();

  // Fetch data from backend
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://berlinlet-afx0jwo8.b4a.run/api/advertise/");
        
        // Sort properties by createdAt (most recent first)
        const sortedByDate = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setProperties(sortedByDate);
        setFilteredProperties(sortedByDate); // Set initial filtered properties
      } catch (error) {
        console.error("Error fetching properties:", error);
      }
    };

    fetchData();
  }, []);

  const handleApplyFilter = () => {
    const newFilteredProperties = properties.filter((property) => {
      const isLocationMatch = !selectedLocation || property.city === selectedLocation;
      const isMaxRentMatch = maxRent === "" || property.price <= maxRent;
      return isLocationMatch && isMaxRentMatch;
    });
    
    setFilteredProperties(newFilteredProperties);
  };

  const sortedProperties = [...filteredProperties].sort((a, b) => {
    if (sortCriteria === "price") {
      return a.price - b.price;
    } else if (sortCriteria === "moveIn") {
      const moveInA = a.moveIn.toLowerCase();
      const moveInB = b.moveIn.toLowerCase();

      if (moveInA === "immediately" && moveInB !== "immediately") {
        return -1;
      }
      if (moveInA !== "immediately" && moveInB === "immediately") {
        return 1;
      }

      return new Date(a.moveIn) - new Date(b.moveIn);
    }
    return 0;  // Default to already sorted by createdAt if no other criteria
  });

  const handleCardClick = (id) => {
    history.push(`/property/${id}`);
  };

  return (
    <div className="recent">
       <div className="filter-sort-container">
    <div className="max-rent-filter">
      <label htmlFor="maxRent" style={{ fontWeight: 'bold', marginRight: '10px' }}>
        Max Rent € :
      </label>
      <input
        type="number"
        id="maxRent"
        value={maxRent}
        onChange={(e) => setMaxRent(e.target.value)}
        placeholder="Enter max rent"
      />
      <button onClick={handleApplyFilter}>Apply</button> {/* Call handleApplyFilter on button click */}
    </div>
  </div>
      <div className="sort-dropdown">
        <label htmlFor="sortCriteria">Sort by: </label>
        <select
          id="sortCriteria"
          value={sortCriteria}
          onChange={(e) => setSortCriteria(e.target.value)}
        >
          <option value="">Select</option>
          <option value="price">Price</option>
          <option value="moveIn">Date</option>
        </select>
      </div>
      <div className="content grid3 mtop">
        {sortedProperties.map((property) => (
          <div key={property.id} onClick={() => handleCardClick(property.id)}>
            <PropertyCardPreview 
              property={property} 
              mobileNumber={property.mobileNumber} 
              email={property.email} 
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const PropertyCardPreview = ({ property, mobileNumber, email }) => {
  const {
    photos,
    name,
    address,
    moveIn,
    moveOut,
    anmeldung,
    deposit,
    price,
    type,
    bedrooms,
    amenities,
  } = property;

  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);

  const handlePrevPhoto = (e) => {
    e.stopPropagation();
    setCurrentPhotoIndex(
      currentPhotoIndex === 0 ? photos.length - 1 : currentPhotoIndex - 1
    );
  };

  const handleNextPhoto = (e) => {
    e.stopPropagation();
    setCurrentPhotoIndex(
      currentPhotoIndex === photos.length - 1 ? 0 : currentPhotoIndex + 1
    );
  };

  const whatsappUrl = `https://wa.me/${mobileNumber}?text=Hey%2C%20Saw%20your%20listing%20in%20RentUp%2C%20I’m%20interested%20in%20the%20apartment%20at%20${encodeURIComponent(
    address
  )}%2C%20Could%20you%20provide%20more%20details%20%3F`;
  
  const emailUrl = `mailto:${email}?subject=Interest%20in%20apartment%20at%20${encodeURIComponent(
    address
  )}&body=Hey%2C%20Saw%20your%20listing%20in%20RentUp%2C%20I’m%20interested%20in%20the%20apartment%20at%20${encodeURIComponent(
    address
  )}%2C%20Could%20you%20provide%20more%20details%20%3F`;
  
  const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
    address
  )}`;

  const handleContactIconClick = (e) => {
    e.stopPropagation();
  };

  const formatDate = (dateString) => {
    if (!dateString) return "Not specified";
    
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    
    return date.toLocaleDateString('en-GB', options).toUpperCase();
  };

  return (
    <div className="box shadow">
      <div className="img">
        <button className="carousel-btn prev-btn" onClick={handlePrevPhoto}>
          &lt;
        </button>
        <img src={photos[currentPhotoIndex]} alt={name} />
        <button className="carousel-btn next-btn" onClick={handleNextPhoto}>
          &gt;
        </button>
      </div>
      <div className="text">
        <div className="category flex">
          <div className="verified">
            <span>Verified</span>
            <i className="fa fa-check-circle"></i>
          </div>
        </div>
        <h4>{name || "No Name Available"}</h4>
        <p>
          <i className="fa fa-location-dot"></i> {address || "No Location Available"}
        </p>
        <p>
          {anmeldung ? (
            <span style={{ color: "green" }}>With Anmeldung</span>
          ) : (
            <span style={{ color: "orange" }}>Without Anmeldung</span>
          )}{" "}
          <br />
          <strong>Move In:</strong> {formatDate(moveIn) || "Not specified"} <br />
          <strong>Move Out:</strong> {formatDate(moveOut) || "Not specified"} <br />
          <strong>Deposit:</strong> €{deposit || "N/A"}
        </p>
        <div className="contact-icons">
          <a
            href={whatsappUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="whatsapp-link"
            onClick={handleContactIconClick}
          >
            <img
              src="https://img.icons8.com/?size=100&id=16713&format=png&color=000000"
              alt="WhatsApp Icon"
              style={{ width: "32px", height: "32px" }}
            />
          </a>
          <a
            href={mapsUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="maps-link"
            onClick={handleContactIconClick}
          >
            <img
              src="https://img.icons8.com/?size=100&id=y9LJ0mNaH6XG&format=png&color=000000"
              alt="Google Maps Icon"
              style={{ width: "32px", height: "32px" }}
            />
          </a>
          <a
            href={emailUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="email-link"
            onClick={handleContactIconClick}
          >
            <img
              src="https://img.icons8.com/?size=100&id=124193&format=png&color=000000"
              alt="Email Icon"
              style={{ width: "32px", height: "32px" }}
            />
          </a>
        </div>
      </div>
      <div className="button flex">
        <span>€{price.toLocaleString()}</span>
        <button className="btn2">More Info</button>
      </div>
    </div>
  );
};

export default RecentCard;
