import "./footer.css";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom"; // Import Link from react-router-dom

const Footer = () => {
  return (
    <>
      <footer>
        <div className="container">
          <div className="box">
            <div className="logo">
              <img src="../images/logo-light.png" alt="Logo" />
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{ width: "100%" }}
                spacing={1}
              >
                {/* Additional content can go here */}
              </Grid>
            </div>

            {/* Add the navigation links here */}
            <div className="footer-links">
              <Link to="/about">About Us</Link>
              <Link to="/how-it-works">How it works</Link>
              <Link to="/advertise">Advertise</Link>
            </div>
          </div>
        </div>
      </footer>
      <div className="legal">
        <span>Made in Berlin with ❤️</span>
      </div>
    </>
  );
};

export default Footer;
