import React from "react";
import Heading from "../../common/Heading";
import "./hero.css";
import { Link } from "react-router-dom";

const Hero = () => {
  // Define the start date and initial number
  const startDate = new Date('2024-10-01'); // Starting date
  const initialNumber = 1002; // Starting number

  // Get the current date
  const currentDate = new Date();

  // Calculate the difference in days between the start date and the current date
  const diffInTime = currentDate.getTime() - startDate.getTime();
  const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24));

  // Calculate the number based on days passed (increasing by 2 every day)
  const currentNumber = initialNumber + (diffInDays * 2);

  return (
    <>
      <section className="hero">
        <div className="container">
          <Heading title="Find Your Next Home" />
          
          {/* Add new line with dynamic increasing number */}
          <p>Over {currentNumber} people have already found their home with RentUp – you could be next! 😊</p>

          {/* Removed the location dropdown form */}
          <div className="advertising-card">
            <h2>Advertise Your Home 🏡</h2>
            <Link to="/advertise" className="btn1">
              Advertise Now
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
