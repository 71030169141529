import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./PropertyCard.css";

const PropertyCardDetail = () => {
  const { id } = useParams();
  const [property, setProperty] = useState(null);
  const [currentImage, setCurrentImage] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProperty = async () => {
      try {
        console.log(`Fetching property with ID: ${id}`); // Debugging line
        const response = await axios.get(`https://berlinlet-afx0jwo8.b4a.run/api/advertise/${id}/`);
        console.log(response.data); // Debugging line
        setProperty(response.data);
        if (response.data.photos && response.data.photos.length > 0) {
          setCurrentImage(response.data.photos[0]);
        }
      } catch (error) {
        console.error("Error fetching property data:", error); // Debugging line
        setError("Error fetching property data");
      } finally {
        setLoading(false);
      }
    };

    fetchProperty();
  }, [id]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!property) return <div className="not-found">Property not found</div>;

  const formatDate = (dateString) => {
    if (!dateString) return "Not specified";
    
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    
    return date.toLocaleDateString('en-GB', options).toUpperCase();  // Example: 16 JAN 2025
  };

  const handleImageClick = (image) => {
    setCurrentImage(image);
  };

  const whatsappUrl = property.mobileNumber
  ? `https://wa.me/${property.mobileNumber}?text=Hey%2C%20Saw%20your%20listing%20in%20RentUp%2C%20I’m%20interested%20in%20the%20apartment%20at%20${encodeURIComponent(property.address)}%2C%20Could%20you%20provide%20more%20details%20%3F`
  : "#"; // Fallback in case mobileNumber is not available


  const emailUrl = property.email
  ? `mailto:${property.email}?subject=Interest%20in%20apartment%20at%20${encodeURIComponent(property.address)}&body=Hey%2C%20Saw%20your%20listing%20in%20RentUp%2C%20I’m%20interested%20in%20the%20apartment%20at%20${encodeURIComponent(property.address)}%2C%20Could%20you%20provide%20more%20details%20%3F`
  : "#"; // Fallback in case email is not available



  return (
    <section className="property">
      <div className="container flex mtop">
        <div className="property-card">
          <div className="property-heading">
            <h2>{property.name}</h2>
            <h4>📍 {property.address}</h4>
          </div>
          <div className="main-image">
            <img src={currentImage} alt={`Main view of ${property.details}`} />
          </div>
          <div className="thumbnail-images">
            {property.photos.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Thumbnail ${index + 1} of ${property.details}`}
                onClick={() => handleImageClick(image)}
                className={currentImage === image ? "active" : ""}
              />
            ))}
          </div>
          <div className="property-description">
            <div className="detail-item">
              <img 
                src="https://img.icons8.com/?size=100&id=dZRN5RsLvy0i&format=png&color=000000" 
                alt="Number of People Icon" 
                className="detail-icon" 
              />
              <p>{property.numberOfPeople} People</p>
            </div>
            <p>{property.description}</p>
            <p><strong>Rent:</strong> €{property.rent}</p>
            <p><strong>Deposit:</strong> €{property.deposit}</p>
            <p><strong>Bedroom:</strong> {property.bedrooms}</p>
            <p><strong>Restroom:</strong> {property.washrooms}</p>
            <p><strong>Living area:</strong> {property.area}</p>
            <p><strong>Move In:</strong> {formatDate(property.moveIn)}</p>
            <p><strong>Move Out:</strong> {formatDate(property.moveOut)}</p>
            <p><strong>Amenities:</strong> {property.amenities}</p>
            <div className="property-info">
              <div className="info-item">
                <p><strong>Smoking:</strong> {property.smoking ? "Allowed" : "Not Allowed"}</p>
              </div>
              <div className="info-item">
                <p><strong>Pet:</strong> {property.pet ? "Allowed" : "Not Allowed"}</p>
              </div>
              <div className="info-item">
                <p><strong>Children:</strong> {property.child ? "Allowed" : "Not Allowed"}</p>
              </div>
            </div>
          </div>
          <div className="apply">
            <p className="apply-text">Apply Now</p>
            <div className="contact-icons">
              <a
                href={whatsappUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="whatsapp-link"
              >
                <img 
                  src="https://img.icons8.com/?size=100&id=16713&format=png&color=000000" 
                  alt="WhatsApp Icon" 
                />
              </a>
              <a
                href={emailUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="email-link"
              >
                <img
                  src="https://img.icons8.com/?size=100&id=124193&format=png&color=000000"
                  alt="Email Icon"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PropertyCardDetail;
