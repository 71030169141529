import React, { useState } from "react";
import "./How.css";
import Heading from "../common/Heading";

const How = () => {
  const [activeTab, setActiveTab] = useState("tenant");

  return (
    <section className="how-it-works">
      <Heading title="How It Works" />
      <div className="container flex">
        <div className="tab-navigation">
          <button
            className={`tab-button ${activeTab === "tenant" ? "active" : ""}`}
            onClick={() => setActiveTab("tenant")}
          >
            For Tenants
          </button>
          <button
            className={`tab-button ${activeTab === "landlord" ? "active" : ""}`}
            onClick={() => setActiveTab("landlord")}
          >
            For Landlords
          </button>
        </div>

        <div className="how-it-works-box">
          {/* Tenant Section */}
          {activeTab === "tenant" && (
            <div className="how-it-works-section">
              <h3>For Tenants</h3>
              <p>Find your new home via RentUp</p>
              <p>
                Are you looking for (temporary) housing? Use our handy filters to
                find suitable rooms, studios, and apartments; respond to your
                favorites; get in direct contact with landlords or housemates, and
                plan your (online) viewing! More than 30,000 tenants have already
                found housing via RentUp in this way in 2022. Will you be the next?
              </p>
              <p>
                🔍 <strong>Daily Checks & Verified Landlords</strong><br />
                Your safety matters to us! 🛡️ That's why our screening team works 
                daily to ensure RentUp stays secure by reviewing all new ads and profiles 
                with both manual checks and automated tools. Additionally, we ask landlords 
                to verify themselves, making the platform even safer. ✅ Look for the green 
                verification badge on RentUp listings to spot verified apartments! 🏠
              </p>
              <p>
                👥 <strong>Direct contact with landlords and housemates</strong><br />
                Found a great place on RentUp? 🏡 Respond to the ad and reach out to the 
                landlord directly! You can ask any questions about the listing and easily 
                schedule a viewing through WhatsApp or Email, whichever works best for you. ✉️
              </p>
            </div>
          )}

          {/* Landlord Section */}
          {activeTab === "landlord" && (
            <div className="how-it-works-section">
              <h3>For Landlords</h3>
              <p>Rent out your living space via RentUp</p>
              <p>
                Do you want to rent out your room, studio, or apartment
                (temporarily) quickly? Place an advertisement on RentUp for free;
                get in touch with qualified tenants, including students, starters,
                and expats, and plan (online) viewings. This way you can find your
                next tenant in no time.
              </p>
              <p>
                <strong>Post an ad on RentUp for free</strong><br />
                With RentUp, you can advertise rooms, studios, apartments, and temporary 
                living spaces at no cost—and rent them out without any fees. Creating an ad 
                is simple: just enter details like the space's size, rental price, and add photos 
                to attract more interest. Once your ad is approved, it goes live instantly, and 
                all potential tenants are notified right away! 
                <a 
                  href="https://rentup.online/advertise" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  style={{ color: '#27ae60' }}
                >
                  Advertise here
                </a>.
              </p>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default How;
